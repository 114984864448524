import type { FooterMenusProps } from "@cm-websites/common";

export const FOOTER_MENUS: FooterMenusProps["menus"] = [
  {
    title: "Häufig gesucht",
    items: [
      {
        label: "Krimidinner für 4 Personen",
        path: "/krimidinner-fuer-4-personen",
      },
      {
        label: "Krimidinner für 5 Personen",
        path: "/krimidinner-fuer-5-personen",
      },
      {
        label: "Krimidinner für 6 Personen",
        path: "/krimidinner-fuer-6-personen",
      },
      {
        label: "Krimidinner für 7 Personen",
        path: "/krimidinner-fuer-7-personen",
      },
      {
        label: "Krimidinner für 8 Personen",
        path: "/krimidinner-fuer-8-personen",
      },
      {
        label: "Krimidinner online spielen",
        path: "/online-krimidinner",
      },
      {
        label: "Krimidinner auf Französisch",
        path: "https://www.culinario-mortale.fr",
        external: true,
      },
      {
        label: "Krimidinner auf Englisch",
        path: "https://www.culinario-mortale.co.uk",
        external: true,
      },
      {
        label: "Alle Sprachen",
        path: "/sprachen",
      },
    ],
  },
  {
    title: "Unsere Krimispiele",
    items: [
      {
        label: "Wie funktioniert ein Krimidinner?",
        path: "/wie-funktioniert-ein-krimidinner-fuer-zuhause",
      },
      {
        label: "Regeln und Ablauf",
        path: "/spielregeln-und-ablauf",
      },
      {
        label: "Häufige Fragen und Antworten",
        path: "/haeufige-fragen",
      },
      {
        label: "Krimidinner selber machen",
        path: "/krimidinner-selber-machen",
      },
      {
        label: "Krimidinner als Grillparty",
        path: "/krimidinner-grillparty",
      },
      {
        label: "Krimidinner zum JGA",
        path: "/krimidinner-jga",
      },
      {
        label: "Händler finden",
        path: "/haendler-finden",
      },

      {
        label: "Rezeptideen für deine Dinnerparty",
        path: "/rezeptideen",
      },
      {
        label: "Rollenbeschreibungen herunterladen",
        path: "/vorbereitung",
      },
      {
        label: "Verlorene Materialien herunterladen",
        path: "/heft-verloren",
      },
    ],
  },
  {
    title: "Informationen",
    items: [
      { label: "Versandkosten & Lieferzeiten", path: "/versandkosten-und-lieferzeiten/" },
      { label: "Händleranfragen", path: "/haendleranfragen/" },
      { label: "B2B / Special Sales", path: "/special-sales/" },
      { label: "Informationen für Autoren", path: "/informationen-fuer-autoren/" },
      { label: "Allgemeine Geschäftsbedingungen", path: "/allgemeine-geschaeftsbedingungen/" },
      { label: "Widerrufsrecht", path: "/allgemeine-geschaeftsbedingungen/#widerrufsrecht" },
      { label: "Datenschutzerklärung", path: "/datenschutz/" },
      { label: "Impressum", path: "/impressum/" },
    ],
  },
];
