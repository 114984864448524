import type { HeaderItem } from "@cm-websites/common";

export const HEADER_ITEMS: HeaderItem[] = [
  { label: "Krimidinner", route: "/" },
  { label: "Bestellen", route: "/#krimidinner-bestellen" },
  { label: "Händler finden", route: "/haendler-finden" },
  {
    label: "Hilfe & Tipps",
    children: [
      {
        label: "Wie funktioniert ein Krimidinner?",
        route: "/wie-funktioniert-ein-krimidinner-fuer-zuhause",
      },
      { label: "Spielregeln und Ablauf", route: "/spielregeln-und-ablauf" },
      { label: "Rollenbeschreibungen herunterladen", route: "/vorbereitung" },
      { label: "Verlorene Materialien herunterladen", route: "/heft-verloren" },
      { label: "Rezepte und Menüvorschläge", route: "/rezeptideen" },
      { label: "Häufige Fragen", route: "/haeufige-fragen" },
      { label: "Kontakt", route: "/kontakt" },
    ],
  },
];
