import { FOOTER_MENUS } from "./footer-menus";
import { FooterContainer, FooterMenus, FooterPaymentsNotice } from "@cm-websites/common";
import React from "react";
import FacebookIcon from "../../public/icons/facebook.svg";
import InstagramIcon from "../../public/icons/instagram.svg";
import PinterestIcon from "../../public/icons/pinterest.svg";

export const Footer: React.FC = () => (
  <FooterContainer>
    <FooterMenus
      headingComponent="div"
      menus={FOOTER_MENUS}
      socialMedia={{
        title: "Social Media",
        items: [
          {
            icon: InstagramIcon,
            label: "Folge uns auf Instagram",
            path: "https://www.instagram.com/culinario.mortale/",
          },
          {
            icon: PinterestIcon,
            label: "Folge uns auf Pinterest",
            path: "https://www.pinterest.de/culinariomortale/",
          },
          {
            icon: FacebookIcon,
            label: "Folge uns auf Facebook",
            path: "https://www.facebook.com/culinario.mortale/",
          },
        ],
      }}
    />
    <FooterPaymentsNotice sx={{ pt: { xs: 4, sm: 6, md: 8, lg: 12 }, pb: 3 }} />
  </FooterContainer>
);
